.container-line {
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }

  .container {
    border: 2px solid rgb(0, 242, 255);
    border-radius: 8px;
    margin: 10px;
    padding: 10px;
    background-color: #d1d1d1;
  }

  table, th, td {
    border: 1px solid black;
  }

/* Remove the default spacing between table cells */
table td,
table th {
  padding: 0;
}

/* Apply styles to the table headers */
.team-stats-table th {
  background-color: #f2f2f2;
  text-align: left;
  padding: 8px;

}

/* Apply blue background color to the first two rows */
.team-stats-table tbody tr:nth-child(-n+2) {
  background-color: rgb(113, 113, 182);
  color: black; /* Optionally, set text color for better visibility */
}

/* Apply green background color to rows 3-6 */
.team-stats-table tbody tr:nth-child(n+3):nth-child(-n+6) {
  background-color: rgb(125, 253, 125);
  color: black; /* Optionally, set text color for better visibility */
}

/* Apply red background color to the last two rows */
.team-stats-table tbody tr:nth-last-child(-n+2) {
  background-color: #ff6969;
  color: black; /* Optionally, set text color for better visibility */
}

/* Apply styles to table cells */
.team-stats-table td {
  padding: 8px;
}


.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th, .custom-table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
}

.custom-table th {
  background-color: #f2f2f2;
}

.custom-table td a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
}

.big-image{
  object-fit: contain;
  width: 60px;
}