body {
    margin: 0;
    padding: 0;
  }

  .sidebar {
    width: 200px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border-right: 2px solid rgb(238, 81, 207);
  }

  .sidebar-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .sidebar-logo {
    width: 100px;
    height: 100px;

    /* Add additional styling as needed */
  }

  .sidebar-search {
    width: 180px;
    height: 30px;
    /* Add additional styling as needed */

    border: 1px solid #ccc; /* Add a border to the input */
  }

  .sidebar-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Allow the container to grow and take up the remaining space */

  }

  .sidebar-item {
    align-items: start;
    text-align: left;
    margin-bottom: 3px;
    padding: 2px;
    transition: background-color 0.3s ease;
    width: 100%; /* Set the desired width of the containers */
  }

  .sidebar-item-content {
    display: flex;
    align-items:  flex-start;
  }

  .small-image-3 {
    margin-right: 10px; /* Adjust margin as needed */
    max-width: 30px;
    max-height: 30px;
    object-fit: contain;
    vertical-align: top;
  }

  .sidebar-item:hover {
    cursor: pointer;
    background-color: #158b91;
  }

  .sidebar-item:not(:last-child) {
    margin-bottom: 10px; /* Add margin-bottom to create space between containers */
  }

  .sidebar-item-title {
    margin: 1px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Adjusted this property */
    font-size: large	;
    height: 100%;
    text-decoration: none;
  }
