

.button-top-page{
  background-color: white;
  color:black;
}

.button-top-page:hover{
  background-color: darkgray;
  color:white;
}

.button-top-page-active{
  background-color: #0056b3;
  color:white;
}

.button-top-page:disabled{
  background: gray;
  color:white;
}

.button-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Wrap the buttons to a new line if needed */
  margin-bottom: 20px; /* Optional: Add some margin at the bottom */
}

/* Add styles for the table container */
.table-container {
  display: flex;
  flex-wrap: wrap;
  width: 25%;
  padding: 10px;
  align-items: center;

}

/* Add styles for each table */
.table {
  width: calc(20% - 40px); /* 20% of the container width minus the margins */
  margin: 20px; /* Optional: Add some margin for spacing between tables */
  table-layout: fixed;
}

.input-field {
  display: block;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.5); /* Set the background color with transparency */
  border: none; /* Remove the border */
  opacity: 0.7;
  height: 30px;
}

.small-image {
  max-width: 30px;
  max-height: 30px;
  min-width: 30px;
  min-height: 30px;
}

.blue-cell {
  background-color:  rgb(199, 199, 240);
  color: rgb(0, 0, 0);
}

.red-cell {
  background-color: rgb(255, 186, 186);
  color: rgb(0, 0, 0);
}

.default-cell {
  background-color: rgb(217, 217, 217);
  color: rgb(0, 0, 0);
}

.blue-cell-img {
  background-color: rgb(199, 199, 240);
  color: rgb(0, 0, 0);
  width: 30px;
  max-width: 30px;
  height: 30px;
  max-height: 30px;
  position: relative;
}

.yellow-cell-img {
  background-color:yellow;
  color: rgb(0, 0, 0);
  width: 30px;
  max-width: 30px;
  height: 30px;
  max-height: 30px;
  position: relative;
}

.red-cell-img {
  background-color: rgb(255, 186, 186);
  color: rgb(0, 0, 0);
  width: 30px;
  max-width: 30px;
  position: relative;
}

.default-cell-img {
  background-color: rgb(217, 217, 217);
  color: rgb(0, 0, 0);
  width: 30px;
  max-width: 30px;
  height: 30px;
  max-height: 30px;
  position: relative;
}

.tables-container {
  display: flex;

  flex-wrap: wrap; /* Set the desired width for the container */

}

.iframe-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  z-index: 1000;
}

.iframe-clipped {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 90px); /* Ensure the height is enough to cover the clipped area */
  border: none;
  clip-path: inset(110px 0 20px 0); /* Clip 110px from top and 20px from bottom */
}
