.background-container {
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    width: 100%;
    height: 100vh; /* Use viewport height for full-screen effect */
  }

  .login-wrapper {
    background-color: rgb(42, 31, 31);
    width: 30%;
    text-align: center; /* Center the content within .login-wrapper */
    /*border: 2px solid rgb(0, 242, 255);*/
    padding: 2px;
    color: white;
    border-radius: 8px;
    overflow: hidden;
    position: relative; /* Add this */
    z-index: 2;
  }

  .login-wrapper::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, transparent, transparent, rgb(0, 242, 255), rgb(0, 242, 255),  rgb(0, 242, 255) );
    z-index: -1; /* Set the z-index to be behind the login form */
    animation: animate 6s linear infinite;
    transform-origin: bottom right;
}

.login-wrapper::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, transparent, transparent, rgb(0, 242, 255), rgb(0, 242, 255),  rgb(0, 242, 255) );
  z-index: -1; /* Set the z-index to be behind the login form */
  animation: animate 6s linear infinite;
  transform-origin: bottom right;
  animation-delay: -3s;
}

  @keyframes animate
  {
    0%
    {
      transform: rotate(0deg);
    }
    100%
    {
      transform: rotate(360deg);
    }
  }

  .label-login {
    display: inline-block;
    width: 150px; /* Adjust the width as needed */
    text-align: right;
    margin-right: 10px;
  }

  .form-group{
    align-items: center; /* Center vertically */
    justify-content: center;
    text-align: center;
    margin-bottom: 5px;
    display: block;
  }

  .button-login {
    border: 2px solid rgb(0, 242, 255);
    color: rgb(0, 0, 0);
    border-radius: 8px;
    padding: 15px 15px;
    text-align: center;
    width: 100%;
    text-decoration: none;
    background-color: rgb(255, 255, 255);
    display: inline-block;
    font-size: 16px;
  }

  .form-container{
    inset: 2px;
    background-color: var(--sidebar-color);
    padding: 50px 20px;
    z-index: 2;
    border-radius: 8px;
  }