.team-performance-table-container {
    position: relative;
    overflow: auto;
    max-height: 600px;
    margin-top: 20px;
    margin-bottom: 20px;

}

.team-performance-table {
    width: 100%;
    border-collapse: collapse;
}

.team-performance-table thead {
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 10;
}

.team-performance-table th, .team-performance-table td {
    padding: 8px 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    z-index: 2;
}


.team-performance-table td:first-child {
    background-color: white;

    position: sticky;
    left: 0;
    z-index: 5;
}

.team-performance-table th:first-child {
    background-color: white;
    position: sticky;
    left: 0;
    z-index: 5;
}

.team-performance-table tbody tr:hover {
    border-top: 4px solid rgba(52, 152, 219, 0.5); /* Top glow */
    border-bottom: 4px solid rgba(52, 152, 219, 0.5);
    transition: border-top 0.3s ease, border-bottom 0.3s ease;
    font-weight: bold;
}


