
.wrapper{
    display: flex;
    flex-wrap: wrap;
}

.draft-wrapper{
    display: flex;
    max-width: 80%;
    width: 80%;
    align-items: center;
    background-color: var(--background-color);
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
}

.drafts-stats{
    width: 20%;
}

.match-draft-container-2{
    width: 31%;
    background-color: var(--background-color);
    padding: 10px;
}

.champion-stats{
    width: 100%;
    background-color: var(--background-color);
    padding: 10px;
    display: flex;
}

.order-table
{
    width: 33%;
    padding: 10px;
}

.yellow-cell {
    background-color: rgb(239, 255, 63);
    color: rgb(0, 0, 0);
  }