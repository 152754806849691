.soloq-page {
    padding: 5px;
    color: #fff;
    font-family: Arial, sans-serif;
    width: 20%;
  }

  .average-stats-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .champion-stats {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;

  }

  .champion-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .champion-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .champion-left {
    display: flex;
    flex-direction: column;

  }

  .champion-name {
    font-weight: bold;
    font-size: 14px;
    color: #fff;
  }

  .champion-cs {
    color: #bbb;
    margin-bottom: 5px;
    font-size: 10px;
  }

  .champion-middle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .champion-kda {
    color: #bbb;
    margin-bottom: 5px;
    font-size: 10px;
    font-weight: bold;
  }

  .champion-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .champion-winrate {
    font-size: 14px;
  }

  .champion-games {

    color: #bbb;
    font-size: 10px;
  }


  .account-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
