.tiers-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .tier, .pool {
    padding: 10px;
    border: 1px solid black;
    display: grid;
    grid-template-columns: repeat(auto-fill, 50px);
    gap: 10px;
    min-height: 80px;
  }

  .pool {
    flex-wrap: wrap;
    margin: 0 auto;

  }

  .champion {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    transition: background-color 0.2s ease;
    box-sizing: border-box;
  }

  .champion.dragging {
    background-color: #e0e0e0;
  }

  button {
    margin-top: 20px;
  }

  .active {
    background-color: #0056b3; /* Example color */
    color: white;
  }