/* LoadingComponent.css */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full screen height */
    color: white
  }

  .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db; /* Blue border for the spinning effect */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Continuous spinning */
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  p {
    margin-top: 20px;
    font-size: 16px;
    color: #333;
  }
