
.league-chooser{
    width: 300px;
    margin: 10px;
    align-items: center;
    color: black;

}

.short-chooser{
    width: 200px;
    margin: 10px;
    align-items: center;
    color: black;
}

.shorter-chooser{
  width: 150px;
  margin: 10px;
  align-items: center;
  color: black;
}

.filters {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid rgb(238, 81, 207);
  border-top: 2px solid rgb(238, 81, 207);
  background-color: var(--sidebar-color);
  color: white;
  height: 100%; /* Set the height of the container to 100% */
}

/* Add styles to the main container */
.main-content {
    margin: 20px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the pagination horizontally */
    border: 2px solid rgb(0, 242, 255);
  }

  /* Add styles to the pagination container */
  .main-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px; /* Add some top margin to separate it from the content above */
  }

  .match-container {
    border-radius: 10px; /* Adjust the value to make the edges more or less rounded */
    border: 1px solid #ccc; /* Add a border to the container for better visibility */
    padding: 10px; /* Add some padding to create space around the content */
    margin: 5px 0; /* Add margin to separate each match container */
    width: 100%;
    display: flex;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .match-container:hover {
    cursor: pointer;
    background-color: #158b91;
  }

  .match-team{
    padding: 10px;
    width: 10%;
  }

  .match-history-row {
    height: 4.6em;
    background-color: lightgray;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
  }

  /* Darker background for every even row */
  .match-history-row:nth-child(even) {
    height: 4.6em;

    background-color: whitesmoke; /* Slightly darker than white */
  }

  /* Hover effect for all rows */
  .match-history-row:hover {
    height: 4.6em;

    background-color: #0056b3;
    color: white;
  }

  /* Ensure hover still works on darker rows */
  .match-history-row:nth-child(even):hover {
    height: 4.6em;
    background-color: #0056b3;
    color: white;
  }


  .match-patch{
    padding: 10px;
    width: 10%;
  }

  .match-id{
    padding: 10px;
  }

  .match-league{
    padding: 10px;
    width: 20%;
  }

  .match-champions{
    padding: 10px;
    display: flex;
    width: 20%;
  }

  .small-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }


.button-match-history{
  background-color: white;
  color: #000000;
  border-radius: 5px;
}

.button-match-history:hover{
  background-color: #0056b3;
  color: white;
}


.leadearboard-row {
  background-color: lightgray;
  height: 3.6em;
}

.leadearboard-row-active {
  background-color: darkblue;
  color: white;
  height: 3.6em;

}

/* Darker background for every even row */
.leadearboard-row:nth-child(odd) {
  background-color: whitesmoke; /* Slightly darker than white */
  height: 3.6em;

}