.react-select__input-container {
    display: inline-flex;
    align-items: center;
    background-color: white;
    border: 1px solid #ced4da;
    border-radius: 4px;
    width: 100%;
    padding: 0.5rem;
    height: 38px; /* Match the height of react-select */
  }

  .react-select__input {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 1rem;
  }

  .react-select__input-container:focus-within {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }

  .react-select__input::placeholder {
    color: #9e9e9e;
  }


  /* Custom checkbox styles */
.custom-checkbox-container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  .custom-checkbox {
    appearance: none;
    width: 30px;
    height: 30px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    outline: none;
    background-color: white;
    transition: background-color 0.2s ease, border-color 0.2s ease;
  }

  .custom-checkbox:checked {
    background-color: #2684ff;
    border-color: #2684ff;
  }

  .custom-checkbox:checked::before {
    content: '✓';
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
  }

  .custom-checkbox:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }

  .checkbox-label {
    margin-left: 8px;
    font-size: 1rem;
  }
