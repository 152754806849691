.custom-date-input__container {
    display: inline-flex;
    align-items: center;
    border: 1px solid #ced4da; /* Light gray border */
    border-radius: 4px;
    padding: 0 8px;
    height: 38px; /* Matching the select height */
    background-color: #fff;
    transition: border-color 0.2s ease-in-out;
    margin-left: 10px;
  }

  .custom-date-input {
    border: none;
    outline: none;
    font-size: 14px;
    color: #495057;
    width: 100%;
  }

  /* Blue border only on focus */
  .custom-date-input:focus {
    outline: none;
  }

  .custom-date-input__container:focus-within {
    border-color: #2684FF; /* Blue border on focus */
  }
