
.league-chooser{
    width: 300px;
    margin: 10px;
    align-items: center;
}

.role-chooser{
    width: 200px;
    margin: 10px;
    align-items: center;
    color: black
}

.slidder{
    height: 200px;
}

.fancy-table-players {
    width: 100%;
    border-collapse: collapse;

}

.fancy-table-players thead {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
}

.fancy-table-players th, .fancy-table-players td {
    padding: 8px 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    z-index: 2;
}

/* First column sticky */
.fancy-table-players td:first-child,
.fancy-table-players th:first-child {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 5;
}

/* Second column sticky */
.fancy-table-players td:nth-child(2),
.fancy-table-players th:nth-child(2) {
    position: sticky;
    left: 37px; /* Adjust this value based on the width of the first column */
    background-color: white;
    z-index: 6;
}


.fancy-table-players tbody tr:hover {
    border-top: 4px solid rgba(52, 152, 219, 0.5); /* Top glow */
    border-bottom: 4px solid rgba(52, 152, 219, 0.5);
    transition: border-top 0.3s ease, border-bottom 0.3s ease;
    font-weight: bold;
}
