.match-draft-container{
    width: 30%;
    background-color: var(--background-color);
    padding: 10px;
    height: 400px;
    color: white;
}

.match-common-container{
    width: 70%;
    background-color: var(--background-color);
    padding: 10px;
    height: 400px;
    justify-content: space-between;
    display: flex;
    flex-direction: column; /* Change flex direction to column */
    gap: 3px;
}

.match-first-graphs{
  background-color: whitesmoke;
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.match-runes{
  padding-top: 30px;
  display: flex;
  color: white;
  width: 100%;
  white-space: nowrap; /* Prevent the text from wrapping to a new line */
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis;
}

.player-runes{
  display: flex;
  flex-direction: column;
  width: 10%;
  align-items: center;
}
.player-rune{
  align-items: center;
}
.button-link-big{
  border: 2px solid rgb(0, 242, 255);
  color: rgb(0, 0, 0);
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
  display: inline-block;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  margin-top: 5px;
  transition: background-color 0.3s ease;
  height: 30px;
}

.button-link-big:hover {
  background-color: #0056b3;
}

.main-wrapper{
  display: flex;
  background-color: var(--background-color);
  flex-wrap: wrap;
}

.match-teamperformance{
  padding: 10px;
  height: 620px;
  background-color: --background-color;
  width: 100%;
  display: flex;
}

.match-teamperformance-table{
  padding: 10px;
  align-items: left;
  width: 30%;
  background-color: var(--sidebar-color);
  border-radius: 8px;
}

.match-playerperformance{
  padding: 0px;
  width: 100%;
  background-color: var(--background-color);
  color: white;
}

.match-images{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.big-image-level1{
  width: 33%;
}

.header-match {
  width: 100%;
  background-color: aliceblue;
  height: 40px;
  align-items: center;
  border: 2px solid rgb(238, 81, 207);
  }

.match-blue-team {
  background-color: lightblue;
  height: 100%; /* Set the height as required */
  width: 50%; /* The blue team div will take the full width of its parent grid cell */
}

/* Styling for the red team div */
.match-red-team {
  background-color: lightcoral;
  height: 100%; /* Set the height as required */
  width: 50%; /* The red team div will take the full width of its parent grid cell */
}

.match-stats{
    display: flex;
    background-color: blueviolet;
    height: 150px;
}

.player-performance{
    display: flex;
}

.name{
    width: 25%;
    padding: 2px;
    white-space: nowrap; /* Prevent the text from wrapping to a new line */
    overflow: hidden; /* Hide any overflow */
    text-overflow: ellipsis;
}
.items{
  width: 50%;
  padding: 2px;
}
.stats{
  width: 25%;
  padding: 2px;
}
.match-playerperformance-table th, .match-playerperformance-table td {
  border-bottom: 1px solid #ddd;
}

.match-playerperformance-table{
  border: 1px solid white;
}

table {
    width: 100%;
    border-collapse: separate; /* Use separate border-collapse to enable border-spacing */
    border-spacing: 0;  /* Remove any gaps between table cells */
  }

  table td {
    padding: 0; /* Remove padding from table cells */
  }

  .inner-table {
    border-collapse: separate; /* Separate cell borders */
    border-spacing: 0; /* Remove spacing between cells */
    width: 100%;
    border: none; /* Remove the outer border */
  }

  .inner-table th, .inner-table td {
    border: 1px solid #000000; /* Add border to individual cells */
    padding: 8px;
    text-align: center;
  }

  .inner-table th {
    background-color: #f2f2f2;
  }

  .inner-table td:first-child {
    text-align: right;
    border-left: none; /* Remove left border of first cell */
  }

  .inner-table th:nth-child(2),
  .inner-table td:nth-child(2) {
    text-align: center;
    border-left: none; /* Remove left border of second cell */
    border-right: none; /* Remove right border of second cell */
  }

  .inner-table td:last-child {
    text-align: left;
    border-right: none; /* Remove right border of last cell */
  }

  .inner-table th:last-child {
    text-align: left;
  }


.blue-cell {
  background-color:  rgb(199, 199, 240);
    color: rgb(0, 0, 0);
  }

.red-cell {
  background-color: rgb(255, 186, 186);
    color: rgb(0, 0, 0);
  }

.default-cell {
    background-color: rgb(217, 217, 217);
    color: rgb(0, 0, 0);
  }

  .blue-cell-img {
    background-color:  rgb(199, 199, 240);
    color: rgb(0, 0, 0);
    width: 30px;
    border: 1px solid #000000;
  }

  .red-cell-img {
    background-color: rgb(255, 186, 186);
    color: rgb(0, 0, 0);
    width: 30px;
    border: 1px solid #000000;
  }

  .red-role-cell-img {
    background-color: rgb(255, 186, 186);
    color: rgb(0, 0, 0);
    width: 30px;
  }

  .blue-role-cell-img {
    background-color:  rgb(199, 199, 240);
    color: rgb(0, 0, 0);
    width: 30px;
  }

  .default-cell-img {
    background-color: rgb(217, 217, 217);
    color: rgb(0, 0, 0);
    width: 30px;
    max-width: 30px;
    border: 1px solid #000000;
  }

  .default-doublecell-img {
    background-color: rgb(217, 217, 217);
    color: rgb(0, 0, 0);
    max-width: 60px;
    width: 60px;
    border-bottom: 1px solid #000000;
  }

  .teamName{
    width: 35%;
    background-color: rgb(217, 217, 217);
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #000000;
  }

  .small-image-2 {
    max-width: 25px;
    max-height: 25px;
    object-fit: cover;
    vertical-align: top;
    margin-right: 0;
  }

  .small-image {
    max-width: 30px;
    max-height: 30px;
    object-fit: contain;
    vertical-align: top;
    margin-right: 0;
  }

  .medium-image {
    max-width: 50px;
    max-height: 50px;
    object-fit: cover;
    vertical-align: top;
    margin-right: 0;
  }




  .timeline-composite {
    position: relative;
    height: 100%; /* Adjust as needed */
    width: 100%;
  }

  .chart-container {
    position: relative;
    height: 100%; /* Adjust as needed */
    width: 100%;
  }

  .event-annotation {
    position: absolute;
  }

  .event-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .event-label img {
    width: 20px; /* Adjust based on your design */
    height: 20px; /* Adjust based on your design */
  }

  .event-label .time {
    background: #fff; /* Background color for time text */
    color: #000; /* Text color */
    border-radius: 4px; /* Rounded corners for the time background */
    padding: 2px 5px; /* Padding inside the time background */
    font-size: 0.75rem; /* Smaller font size for time */
    margin-top: 5px; /* Space between icon and time */
  }


  .event-annotation {
    transform: translate(-50%, -50%); /* Center the annotation */
  }

  .event-annotation .time {
    position: absolute;
    top: 100%; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    padding: 2px 5px;
    border-radius: 2px;
    white-space: nowrap;
  }

  .event-annotation img {
    width: 20px; /* Adjust as needed */
    height: 20px; /* Adjust as needed */
  }
