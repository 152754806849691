.app-container {
    width: 100%;
    height: 100%;
    --sidebar-color:  rgb(67, 62, 62);
    background-color: var(--background-color);
  }

  .sidebar {
    width: 200px;
    background-color: var(--sidebar-color);
    height: 100vh;
  }

  .content {
    margin-left: 200px;
    flex: 1;
    min-height: 100vh;

  }

  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }


