.week-day,
.day {
  display: inline-block;
  width: 40px;
  text-align: center;
  font-weight: bold;
}

.week-days-container {
  margin-bottom: 10px;
}

.calendar-container {
  display: flex;
  flex-wrap: wrap;
}

.calendar-day {
  width: calc(100% / 7);
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.week-days {
  display: flex;
  justify-content: space-between;
}

.week-day {
  flex: 1;
  text-align: center;
}

.note {
  width: 100%;
  min-height: 90px;
  resize: none;
  font-size: 12px;
}

.today {
  color: red; /* or any color you prefer */
}